<template>
      <div class="features">
            <div class="row">
                  <div class="col-12 md:col-4 feature interior">
                        <div>
                              <p class="title">BUILDING FEATURES</p>
                              <ul class="descriptions">
                                    <li class="item">
                                          1. Boutique building with 12 residential floors plus 1 level of retail on the ground floor.
                                    </li>
                                    <li class="item">2. Ground floor indoor amenity area offering a fitness room as well as a bike repair and long-term bike storage room.</li>
                                    <li class="item">3. 2nd floor indoor amenity area offering a children’s homework room and co-working space.</li>
                                    <li class="item">4. 4th floor amenity area offering a combined co-working space.</li>
                                    <li class="item">5. 5th floor amenity area offering a co-working lounge and private meeting room.</li>
                                    <li class="item">6. 6th floor indoor amenity area offering private offices as well as boardroom.</li>
                                    <li class="item">7. 11th floor roof top outdoor amenity area.</li>
                                    <li class="item">8. Contemporary lobby designed by Patton Design Studio.</li>
                                    <li class="item">9. Mail and parcel area conveniently located by entrance.</li>
                                    <li class="item">10. Key FOB controlled access system at entry points and garage.</li>
                                    <li class="item">11. Two elevators accessible from parking area and lobby.</li>
                                    <li class="item">12. Underground vehicular parking.</li>
                                    <li class="item">13. Bicycle storage area in parking garage.</li>
                                    <li class="item">
                                          14. One Valet virtual concierge service which includes security features, amenity booking management and parcel service management.
                                    </li>
                              </ul>
                        </div>
                        <div>
                              <p class="title">INTERIOR FEATURE</p>
                              <ul class="descriptions">
                                    <li class="item">1. Innovative suite design featuring 9’ ceiling height throughout, except where ducts and venting for mechanical systems, acoustic ceilings and structure are required.</li>
                                    <li class="item">2. All units on floor 2 to have 8’ ceiling height throughout, except where ducts and venting for mechanical systems, acoustic ceilings and structure are required.</li>
                              </ul>
                        </div>
                  </div>
                  <div class="col-12 md:col-4 feature interior">
                        <div class="mt-30">
                              <ul class="descriptions">
                                    <li class="item">3. All units on the 9th floor to have 8’8” ceiling height throughout, except where ducts and venting for mechanical systems, acoustic ceilings and structure are required.</li>
                                    <li class="item">4. All interior walls painted with a single colour of latex paint from Vendor’s standard sample.</li>
                                    <li class="item">5. Designer selected laminate wide plank flooring throughout suites.</li>
                                    <li class="item">6. Choice of ceramic floor tile in bathroom(s) from Sierra’s standard specifications.</li>
                                    <li class="item">7. Solid Core entry doors.</li>
                                    <li class="item">8. Smooth painted ceilings throughout.</li>
                                    <li class="item">9. Modern trim package throughout including baseboards, door and window casings.</li>
                                    <li class="item">10. Moulded panel interior passage doors.</li>
                                    <li class="item">11. Satin nickel finish lever style door handles and hinges.</li>
                                    <li class="item">12. Wire shelving installed in all closets.</li>
                              </ul>
                        </div>
                        <div>
                              <p class="title">KITCHEN</p>
                              <ul class="descriptions">
                                    <li class="item">1. Choice of cabinetry and Quartz countertops from Sierra’s standard samples.</li>
                                    <li class="item">2. Stainless steel undermount kitchen sink including a single lever faucet with pull-out spray from Sierra’s standard samples.</li>
                                    <li class="item">3. Modern European style Stainless steel glass top range, refrigerator and dishwasher.</li>
                                    <li class="item">4. Insert kitchen appliance sizes as per suite layout/spec.</li>
                                    <li class="item">5.All D, E & F type units to have 30" modern refrigerator,30" stainless steel glass top range, and 24" stainless steel dishwashe</li>
                                    <li class="item">6. Exterior vented over-the-range combination microwave and hood fan.</li>
                                    <li class="item">7. Glazed ceramic tile backsplash.</li>
                                    <li class="item">8. Countertop electrical receptacle for small appliances.</li>
                              </ul>
                        </div>
                  </div>
                  <div class="col-12 md:col-4 feature interior">
                        <div>
                              <p class="title">Bathroom(s)</p>
                              <ul class="descriptions">
                                    <li class="item">1. Choice of cabinetry and Quartz countertops, except where pedestal sink is provided, from Sierra’s standard samples.</li>
                                    <li class="item">2. Single lever faucet from Sierra’s standard specification.</li>
                                    <li class="item">3. Quality chrome bathroom fixtures.</li>
                                    <li class="item">4. White acrylic bathtubs in all bathrooms as per plan.</li>
                                    <li class="item">5. Frameless glass panel in shower stall – only where applicable as per plans.</li>
                                    <li class="item">6. Exhaust fans vented to exterior in all bathroom(s) and powder room.</li>
                                    <li class="item">7. Privacy locks on all bathroom and powder room doors.</li>
                                    <li class="item">8. Mirror provided in all bathroom(s).</li>
                                    <li class="item">9. Ceramic wall tile for tub and shower enclosure(s) and separate shower stall (as per plan), from Sierra’s standard samples.</li>
                                    <li class="item">10. Chrome towel bar and tissue holder in bathroom and powder room.</li>
                              </ul>
                        </div>
                        <div>
                              <p class="title">Electrical</p>
                              <ul class="descriptions">
                                    <li class="item">1. Decora style switches and receptacles throughout.</li>
                                    <li class="item">2. Individually controlled heating and cooling system.</li>
                                    <li class="item">3. Smoke detectors and carbon monoxide detectors installed as per Ontario Building Code.</li>
                                    <li class="item">4. All suites roughed-in for TV and Internet.</li>
                              </ul>
                        </div>
                        <div>
                              <p class="title">Laundry</p>
                              <ul class="descriptions">
                                    <li class="item">1. All A and B type units to have 24" unitized washer and dryer. All C, D, E and F type units to have 27" unitized washer and dryer.</li>
                                    <li class="item">2. Tile flooring below washer and dryer.</li>
                              </ul>
                        </div>
                        <div>
                              <p class="title">Warranty</p>
                              <ul class="descriptions">
                                    <li class="item">1. Your new home will be registered with the Tarion Warranty Program. The details of the warranty can be found at www.tarion.com.</li>
                              </ul>
                        </div>
                  </div>
            </div>
      </div>
</template>

<script>
      export default {
            name: 'features',
            mounted(){
                  if(window.matchMedia("(max-width: 1400px)").matches) {
                        document.querySelector('body').style.overflowY = 'scroll';
                        // document.querySelector('html').style.overflowY = 'scroll';
                  }
                  window.addEventListener('resize', this.resizeHandler)
            },
            beforeDestroy(){
                  if(window.matchMedia("(max-width: 1400px)").matches) {
                        document.querySelector('body').style.overflowY = 'hidden';
                        // document.querySelector('html').style.overflowY = 'hidden';
                  }
                  window.removeEventListener('resize', function(){})
            },
            methods:{
                  resizeHandler(){
                        if(window.matchMedia("(max-width: 1400px)").matches) {
                              document.querySelector('body').style.overflowY = 'scroll';
                        } else {
                              document.querySelector('body').style.overflowY = 'hidden';
                        }
                  }
            }
      }
</script>

<style lang='scss'>
@import 'Features';
</style>