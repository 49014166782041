<template>
      <div class="features-page">
            <Features/>
            <div class="row feature-footer">
                  DISCLAIMER: The Vendor shall have the right to make reasonable changes in the opinion of the Vendor in the plans and specifications if required and to substitute other material for that provided for herein with material that is of equal or better quality than that provided for herein. The determination of whether or not a substitute material is of equal of better quality shall be made by the Vendor whose determination shall be final and binding. Colour, texture, appearance, etc. of features and finishes installed in the Unit may vary from Vendor’s samples as a result of normal manufacturing and installation processes. E & O.E.
            </div>
      </div>
</template>

<script>
      import Features from '@cp/features/Features.vue';
      export default {
            name: 'features',
            components: { Features }
      }
</script>

<style lang='scss'>
      .features-page {
            border-top: 1px solid $mainBorderColor;

            .feature-footer {
                  color: $white;
                  opacity: .6;
                  text-align: justify;
                  padding: 22px;
                  font-size: 12px;
                  font-family: $vaud-normal;
                  // @include toRem(font-size, 12);

                  @media screen and (max-width: 1366px) {
                        font-size: smaller;
                  }
            }
      }
</style>